import React from 'react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import MDXLink from '../MDXLink';
import { MainTitle, TitleRead } from './CommonElement';
import Nl2Br from './Nl2Br';
import SharedCss from './SharedCss';

const PPWrapper = styled.div`
  padding: 50px 40px;
  background: #000 url(/img/projects/plan-bg.jpg) center;
  background-size: cover;

  /** Common Css **/
  ${SharedCss}

  /* PC */
  @media (min-width: 1024px) {
    padding: 160px 40px;
  }
`;

const PPTitleWHT = styled.div`
  body & {
    color: #fff;
    text-align: center;

    /* PC */
    @media (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }
`;

const PPTitleRead = styled(TitleRead)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }
`;

const PPAddition = styled.p`
  body & {
    max-width: 42rem;
    margin: 0 auto;
    font-size: 11px;
    color: #fff;
    white-space: break-spaces;
    /* TAB */
    @media (min-width: 768px) {
      text-align: left;
    }

    /* PC */
    @media (min-width: 1024px) {
      max-width: 100rem;
      font-size: 14px;
    }
  }
`;

const ButtomText = styled.p`
  body & {
    max-width: 42rem;
    margin: 24px auto 16px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    white-space: break-spaces;

    /* PC */
    @media (min-width: 1024px) {
      max-width: 100rem;
      font-size: 24px;
    }
  }
`;

const PRLink = styled(MDXLink)`
  body & {
    display: block;
    width: 90%;
    max-width: 400px;
    height: 80px;
    margin: 20px auto 0;
    font-size: 24px;
    line-height: 80px;
    color: #fff !important;
    text-align: center;
    text-decoration: none;
    background: #0093bb;
    border-radius: 50px;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

const PRButton = styled.button`
  body & {
    display: block;
    width: 90%;
    max-width: 400px;
    height: 80px;
    margin: 20px auto 0;
    font-size: 24px;
    line-height: 80px;
    color: #fff !important;
    text-align: center;
    text-decoration: none;
    background: #0093bb;
    border: none;
    border-radius: 50px;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

const DATA_QUERY = graphql`
  query PricingPlansData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-pricing-plan" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            mainTitle
            subHeadingText
            pricingAddition
            linkUrl
            linkText
            hiddenPricingPlans {
              plans {
                title
                note
                featured
              }
              features {
                title
                tiers {
                  title
                  value
                  note
                }
              }
            }
            buttomText
          }
        }
      }
    }
  }
`;

const PricingPlans: React.FC<{
  updateCalendlyModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ updateCalendlyModalIsOpen }) => {
  const data = useStaticQuery<GatsbyTypes.PricingPlansDataQuery>(
    DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const features = frontmatter?.hiddenPricingPlans?.features;
  const plans = frontmatter?.hiddenPricingPlans?.plans;

  return (
    <PPWrapper id="plan">
      <PPTitleWHT>
        <MainTitle>{frontmatter?.mainTitle}</MainTitle>
        {frontmatter?.subHeadingText && (
          <PPTitleRead>{frontmatter?.subHeadingText}</PPTitleRead>
        )}
      </PPTitleWHT>
      <div className="max-w-5xl mx-auto">
        <section
          aria-labelledby="mobile-comparison-heading"
          className="lg:hidden"
        >
          <div className="max-w-2xl mx-auto py-8 my-4 bg-white rounded">
            {plans?.map((plan, mobilePlanIndex) => (
              <div
                key={mobilePlanIndex}
                className={classNames(
                  mobilePlanIndex === 0 ? 'bg-[#F6F6F5]' : '',
                  'pb-8 px-4 border-b sm:px-6',
                )}
              >
                <div className="border-transparent -mt-px pt-6 border-t-2">
                  <h3 className="text-gray-900 text-md font-normal text-center">
                    {mobilePlanIndex === 0 || mobilePlanIndex === 1 ? (
                      <>{plan?.title}</>
                    ) : null}
                    <span className="text-[0.5rem]">{plan?.note}</span>
                  </h3>
                </div>
                <h4 className="mt-10 text-md font-normal text-gray-900"></h4>

                <div className="mt-6 relative">
                  {/* Fake card background */}
                  <div
                    aria-hidden="true"
                    className="hidden absolute inset-0 pointer-events-none sm:block"
                  >
                    <div className="shadow-md absolute right-0 w-1/2 h-full bg-white rounded-lg" />
                  </div>

                  <div className="ring-1ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                    <dl className="divide-y divide-gray-200">
                      {features?.map((feature, i) => (
                        <div
                          key={i}
                          className="py-3 flex items-center justify-between sm:grid sm:grid-cols-2"
                        >
                          <dt className="pr-4 text-md font-normal text-gray-900">
                            {feature?.title}
                          </dt>
                          <dd className="flex items-center justify-end sm:px-4 sm:justify-center">
                            <span className="text-gray-900 text-md font-normal whitespace-nowrap">
                              {feature?.tiers &&
                                feature?.tiers[mobilePlanIndex] && (
                                  <>
                                    {feature?.tiers[mobilePlanIndex]?.value}
                                    <span className="text-[0.5rem]">
                                      {feature?.tiers[mobilePlanIndex]?.note}
                                    </span>
                                  </>
                                )}
                            </span>
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section
          aria-labelledby="comparison-heading"
          className="bg-white hidden lg:block rounded-lg"
        >
          <div className="mx-auto mb-8">
            <div className="w-full border-gray-200 flex items-stretch">
              <div className=" w-1/5 py-6 pr-4 flex items-end">
                <h3 className="mt-auto text-md font-normal text-gray-900"></h3>
              </div>
              {plans?.map((plan, planIdx) => (
                <div
                  key={planIdx}
                  aria-hidden="true"
                  className={classNames(
                    planIdx === 0 ? 'bg-[#F6F6F5]' : '',
                    'w-1/5',
                  )}
                >
                  <div className="py-4">
                    <p className="text-gray-900 text-md font-normal text-center">
                      {planIdx === 2 || planIdx === 0 ? (
                        <>{plan?.title}</>
                      ) : null}
                      <span className="text-[0.5rem]">{plan?.note}</span>
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="relative">
              <table className="relative w-full">
                <thead>
                  <tr className="text-left">
                    <th scope="col" className="bg-gray-300">
                      <span className="sr-only">Feature</span>
                    </th>
                    {plans?.map((plan, i) => (
                      <th key={i} scope="col" className="bg-gray-300">
                        <span className="sr-only">{plan?.title} plan</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {features?.map((feature, i) => (
                    <tr key={i}>
                      <th
                        scope="row"
                        className="w-1/5 py-3 px-4 text-left text-md font-normal text-gray-600"
                      >
                        {feature?.title}
                      </th>
                      {feature?.tiers?.map((tier, tierIdx) => (
                        <td
                          key={tierIdx}
                          className={classNames(
                            tierIdx === 0 ? 'bg-[#F6F6F5]' : '',
                            'px-4 relative w-1/5 py-0 text-center',
                          )}
                        >
                          <span className="relative w-full h-full py-3">
                            {typeof tier?.value === 'string' ? (
                              <span className="text-gray-900 text-md font-normal">
                                {tier?.value}
                                <span className="text-[0.5rem]">
                                  {tier?.note}
                                </span>
                              </span>
                            ) : null}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <PPAddition>
          <Nl2Br text={frontmatter?.pricingAddition} />
        </PPAddition>
        {frontmatter?.buttomText ? (
          <ButtomText>
            <Nl2Br text={frontmatter?.buttomText} />
          </ButtomText>
        ) : null}
      </div>
      {frontmatter?.linkUrl === 'button' ? (
        <PRButton
          onClick={() => updateCalendlyModalIsOpen?.((current) => !current)}
        >
          {frontmatter?.linkText}
        </PRButton>
      ) : (
        <PRLink href={frontmatter?.linkUrl ?? ''}>
          {frontmatter?.linkText}
        </PRLink>
      )}
    </PPWrapper>
  );
};

export default PricingPlans;
